import { useCallback, useEffect, useState } from 'react';
import { isEmpty, toString } from 'lodash';
import './App.css';
import { Amplify, Storage } from 'aws-amplify';
import { Auth } from '@aws-amplify/auth'
import moment from 'moment';
import { BrowserRouter, Navigate, Route, Routes, useSearchParams } from 'react-router-dom';

Amplify.configure({
    Storage: {
        AWSS3: {
            bucket: 'data-doc32', //REQUIRED -  Amazon S3 bucket name
            region: 'ap-south-1', //OPTIONAL -  Amazon service region,
        }
    }
});
Auth.configure({
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
    region: 'ap-south-1',
    mandatorySignIn: false,
})

function App() {
    const [searchParam, setSearchParam] = useSearchParams();
    const [msg, setMsg] = useState<{text: string; color: string} | undefined>();
    const [afterImg, setAfterImg] = useState<{
        avalable: boolean;
        uris: string[];
        viewIndex: number;
        loading?: boolean,
    }>({
        avalable: false,
        uris: [],
        viewIndex: -1,
    });
    const [beforeImg, setBeforeImg] = useState<{
        avalable: boolean;
        uri: string;
        uploaded?: boolean;
    }>({
        avalable: false,
        uri: ''
    });
    const [image, setImage] = useState<File | undefined>();
    const [query, setQuery] = useState<{loading: boolean, progress: number} | undefined>({loading: false, progress: 0});
    const [upload, setUpload] = useState<{progress: number; running: boolean;}>({running: false, progress: 0})
    const [timer, setTimer] = useState<NodeJS.Timeout | undefined>();
    const [existingJob, setExistingJob] = useState<{
        jobId: string;
    } | undefined>();

    const getTeethImgs = useCallback((jobId: string) =>  {
        setQuery({progress: 0, loading: true});
        fetch(process.env.REACT_APP_IMAGE_API + "/fetch", {
            method: "POST",
            body: JSON.stringify({
                jobid: jobId
            })
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            const uris = res.Images || [];
            
            if (res.job_status === 'Completed' && existingJob){
                const uriparts = uris[0].split('/');
                const root = uriparts.slice(0, uriparts.length-1).join('/');

                const firstImageName = uriparts[uriparts.length-1];
                const firImageNameParts = firstImageName.split('.');
                const firstImageKeyParts = firImageNameParts[0].split('_')
                setBeforeImg({
                    avalable: true,
                    uri: root+ "/"+ firstImageKeyParts.slice(0, firstImageKeyParts.length-1).join('_')+ "."+ firImageNameParts[1],
                    uploaded: true
                })
                setAfterImg({
                    avalable: !!res.Images,
                    uris: res.Images || [],
                    viewIndex: res.Images? 0: afterImg.viewIndex
                })
                setMsg({text: "Process complete.", color: 'green'})
                setQuery({loading: false, progress: 100})
            }
            else if (res.job_status.split(" ")[0] === 'Failed') {
                setMsg({text: res.job_status, color: 'red'})
                setQuery({progress: 0, loading: false});
            }else{
                setMsg({text: "Processing. It may take above 20 seconds", color: 'orange'})
                setTimer(setTimeout(() => {
                    if (existingJob && existingJob.jobId){
                        getTeethImgs(existingJob.jobId);
                    }
                }, 5000));
            }
        })
        .catch(err => {
            console.log(err)
            setMsg({text: toString(err), color: 'red'})
            setAfterImg({...afterImg, loading: false})
        })
    }, [beforeImg, existingJob, afterImg]);

    const getBeforeImg = useCallback((jobId: string, imageKey: string)=>{
        Storage.get(`dev/public/${jobId}/`+ imageKey, {
            customPrefix:{
                'public': ""
            }
        })
        .then(res => {
            setBeforeImg({
                avalable: true,
                uri: res,
                uploaded: true
            })
        })
        .catch(err => {
            console.log(err);
        })
    }, []);

    const uploadImage = useCallback(() => {
        if (!image){
            return;
        }
        setUpload({progress: 0, running: true})
        const jobId = "teeth-job-"+ toString(moment().unix());
        try {
            Storage.put(`dev/public/${jobId}/`+ image.name, image,{
                contentType: image.type,
                level: 'public',
                customPrefix:{
                    'public': ""
                },
                progressCallback: (progress)=> {
                    setUpload({running: true, progress: (progress.loaded / progress.total)*100})
                },
            })
            .then((res: any) => {
                setUpload({progress: 100, running: false})
                if (!isEmpty(res.failure)){
                    setMsg({text: 'Server is too busy, please try again in few minutes.', color: 'orange'})
                }
                fetch(process.env.REACT_APP_IMAGE_API + "/trigger", {
                    method: "POST",
                    body: JSON.stringify({
                        "image_key": image.name, 
                        "jobid": jobId
                    })
                }).then(res => {
                    const job  = {jobId, uploadedImgUri: beforeImg.uri, imageKey: image.name};
                    localStorage.setItem("smiles-teeth-imagery", JSON.stringify(job))
                    
                    setMsg({text: 'successfully uploaded', color: 'green'})
                    setImage(undefined)
                    setBeforeImg({...beforeImg, uploaded: true})
                    window.open(window.location.origin+window.location.pathname+ `?jobId=${job.jobId}&imageKey=${job.imageKey}`, '_blank')?.focus();
                })
                .catch(err=> {
                    console.log(err)
                    setMsg({text: toString(err), color: 'red'})
                })
            })
            .catch(err => {
                console.log(err)
                setMsg({text: toString(err), color: 'red'})
                setUpload({progress: 0, running: false})
            });
            } catch (err) {
                console.log("Error uploading file: ", err);
                setMsg({text: toString(err), color: 'red'})
            }
        
    }, [image, beforeImg, afterImg, existingJob]);

    useEffect(() => {
        if (image){
            setBeforeImg({
                avalable: true,
                uri: URL.createObjectURL(image)
            });
            setAfterImg({
                avalable: false,
                uris: [],
                viewIndex: -1
            })
        }
    }, [image])

    useEffect(() => {
        const urlJobId = searchParam.get('jobId');
        const imageKey = searchParam.get('imageKey');
        if (urlJobId){
            setExistingJob({ jobId: urlJobId})
            if (imageKey){
                getBeforeImg(urlJobId, imageKey)
            }
        }
    }, [searchParam]);

    useEffect(() => {
        if (timer){
            clearTimeout(timer)
        }
        if (!isEmpty(existingJob) && !image){
            setTimer(
                setTimeout(() =>{
                    setMsg({text: "Process is being carried out.", color: 'orange'})
                    getTeethImgs(existingJob.jobId)
            }, 2000))
        }
    }, [existingJob, image]);

    // useEffect(()=>{
    //     console.log(beforeImg)
    // }, [beforeImg])
  return (
    <div className="p-3">
        <div className='w-100' style={{position: 'fixed', top: 0, left: 0}}>
            <nav className="navbar bg-light shadow-sm w-100">
                <div className="d-flex w-100 ">
                    <a className="navbar-brand" href="#">
                        <img className=' p-2 mx-4' src={process.env.PUBLIC_URL+"/smiles_logo.png"} alt="" style={{width: "8em", height: "3em", backgroundColor: 'rgb(91, 91, 196)'}}/>
                    </a>
                    <div className='d-flex justify-content-center align-items-center main-header'>
                        <h3 className=''>Smiles Assesment</h3>
                    </div>
                </div>
            </nav>
            {
                upload.running &&
                <div className="progress" >
                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{width: `${upload.progress}%`}} aria-valuemin={0} aria-valuemax={100}>
                    </div>
                    {`upload progress ${upload.progress}%`}
                </div>
            }
            {
                msg && msg.text && 
                <div className='d-flex justify-content-center align-items-center bg-white' style={{height: "50px"}}>
                    <div className='mx-3' style={{color: msg.color}}>
                        <i className='bi bi-info-circle me-2'></i>
                        <span >
                            {msg.text}
                        </span>
                    </div>
                    <div className='' style={{width: '3em'}}>
                        {
                            query?.loading &&
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                    </div>
                </div>
            }
        </div>
        
        <div className='p-5 pt-0' style={{marginTop: '100px'}}>
            <div className='d-flex flex-wrap justify-content-center p-3'>
                <div className='sample mx-4 my-3 border shadow'>
                    <div className='' style={{width: '25em', height: '25em'}}>
                        <img src={process.env.PUBLIC_URL+ "/sample_teeth0.jpeg"} alt="" style={{width: '100%', height: '100%'}}/>
                    </div>
                    <div className='d-flex justify-content-center px-2 py-1'>  
                        <h4>Sample Image</h4>
                    </div>
                    <div className='d-flex flex-wrap justify-content-center px-2 py-1' style={{width: '25em'}}>  
                        <p className='text-warning text-center'><i className='bi bi-exclamation-triangle'></i> Please ensure your face and both upper and lower teeth are visible.</p>
                    </div>
                </div>
                <div className='before mx-4 border shadow my-3'>
                    <div className='' style={{width: '25em', height: '25em'}}>
                        {   beforeImg.avalable? 
                            <img src={beforeImg.uri} alt="" style={{width: '100%', height: '100%'}}/>
                            : <svg className="bd-placeholder-img card-img-top" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect><text x="30%" y="50%" fill="#dee2e6" dy=".3em">{existingJob?.jobId? "Loading... Please wait.":"Please upload an Image"}</text></svg>
                        }
                    </div>
                    <div className="py-1 px-2">
                        <input className="form-control" type="file" id="formFileMultiple" 
                            onChange={(e)=> {
                                const file = e.target.files? e.target.files[0]: undefined
                                const ext = file?.type.split('/')[1];
                                const allowedExt = ['jpg', 'jpeg', 'png'];
                                if (!file || !ext || !allowedExt.includes(ext)){
                                    setMsg({text: 'Invalid file! only '+ allowedExt.join(', ')+ ' files are allowed.', color: 'orange'}); 
                                    return;
                                }
                                setImage(file);
                                setAfterImg({
                                    avalable: false,
                                    uris: [],
                                    viewIndex: -1
                                });
                                setMsg(undefined)
                            }}
                        />
                    </div>
                    <div className='d-flex justify-content-between align-items-center px-2 py-1'>
                        <div>

                        </div>
                        <h5>
                            Before treatment
                        </h5>
                        <button className='btn btn-primary me-2' 
                                onClick={()=>{uploadImage()}} disabled={isEmpty(image?.name)}
                            >{'Upload'}</button>
                    </div>
                </div>

                <div className='after mx-4 my-3 border shadow'>
                    <div className='' style={{width: '25em', height: '25em'}}>
                        {
                            afterImg.avalable? 
                            <img src={afterImg.uris[afterImg.viewIndex]} alt="" style={{width: '100%', height:'100%'}}/>
                            : 
                            <svg className="bd-placeholder-img card-img-top" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: Image cap" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect><text x="15%" y="50%" fill="#dee2e6" dy=".3em">Processed image can be viewed here.</text></svg>
                        }
                    </div>
                    <div className='d-flex justify-content-between align-items-center my-4 px-2'>
                        <button className='btn btn-primary'
                            onClick={()=>{setAfterImg({...afterImg, viewIndex: afterImg.viewIndex-1})}}
                            disabled={!(afterImg.avalable && afterImg.viewIndex > 0)}
                        >
                            <i className='bi bi-caret-left' style={{fontSize: '100%'}}></i>
                        </button>
                        <h5>
                            After treatment
                        </h5>
                        <button className='btn btn-primary'
                            onClick={()=>{setAfterImg({...afterImg, viewIndex: afterImg.viewIndex+1})}}
                            disabled={!(afterImg.avalable && afterImg.viewIndex < afterImg.uris.length-1)}
                        >
                            <i className='bi bi-caret-right' style={{fontSize: '100%'}}></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

const AppPage = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/app' element={<App />}/>
                <Route path='*' element={<Navigate to="/app" />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppPage;